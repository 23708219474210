var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-overlay',{attrs:{"show":_vm.isShowLoading}},[_c('validation-observer',{ref:"validateForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.saveData($event)}}},[_c('b-card',{attrs:{"title":_vm.$t('user.personalInformation')}},[_c('b-row',[(_vm.userData.userCategory === 1)?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('master.company.name') + '*'}},[(_vm.id)?_c('b-form-input',{attrs:{"plaintext":""},model:{value:(_vm.showCompanyName),callback:function ($$v) {_vm.showCompanyName=$$v},expression:"showCompanyName"}}):_c('validation-provider',{attrs:{"name":_vm.$t('master.company.name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:errors.length > 0 ? 'vue-select is-invalid' : null,attrs:{"label":"companyName","options":_vm.optionCompanies,"reduce":function (option) { return option.companyId; }},model:{value:(_vm.companyId),callback:function ($$v) {_vm.companyId=$$v},expression:"companyId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1):_vm._e(),(_vm.userData.userCategory === 1)?_c('b-col',{attrs:{"md":"6"}},[(_vm.isInternalCompany)?_c('b-form-group',{attrs:{"label":_vm.$t('user.staffId') + '*'}},[_c('validation-provider',{attrs:{"name":_vm.$t('user.staffId'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"plaintext":_vm.isPlainText},model:{value:(_vm.userStaffId),callback:function ($$v) {_vm.userStaffId=$$v},expression:"userStaffId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e()],1):_vm._e(),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('user.idCard') + '*'}},[_c('validation-provider',{attrs:{"name":_vm.$t('user.idCard'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"plaintext":_vm.isPlainText},model:{value:(_vm.userIdCard),callback:function ($$v) {_vm.userIdCard=$$v},expression:"userIdCard"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('user.prefix') + '*'}},[(_vm.isPlainText)?_c('b-form-input',{attrs:{"plaintext":""},model:{value:(_vm.showPrefixName),callback:function ($$v) {_vm.showPrefixName=$$v},expression:"showPrefixName"}}):_c('validation-provider',{attrs:{"name":_vm.$t('user.prefix'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:errors.length > 0 ? 'vue-select is-invalid' : null,attrs:{"label":"prefixName","options":_vm.optionPrefixes,"reduce":function (option) { return option.prefixId; }},model:{value:(_vm.prefixId),callback:function ($$v) {_vm.prefixId=$$v},expression:"prefixId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('user.firstName') + '*'}},[_c('validation-provider',{attrs:{"name":_vm.$t('user.firstName'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"plaintext":_vm.isPlainText},model:{value:(_vm.userFirstName),callback:function ($$v) {_vm.userFirstName=$$v},expression:"userFirstName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('user.lastName') + '*'}},[_c('validation-provider',{attrs:{"name":_vm.$t('user.lastName'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"plaintext":_vm.isPlainText},model:{value:(_vm.userLastName),callback:function ($$v) {_vm.userLastName=$$v},expression:"userLastName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('user.position') + '*'}},[(_vm.isPlainText)?_c('b-form-input',{attrs:{"plaintext":""},model:{value:(_vm.showPositionName),callback:function ($$v) {_vm.showPositionName=$$v},expression:"showPositionName"}}):_c('validation-provider',{attrs:{"name":_vm.$t('user.position'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:errors.length > 0 ? 'vue-select is-invalid' : null,attrs:{"label":"positionName","options":_vm.optionPositions,"reduce":function (option) { return option.positionId; }},model:{value:(_vm.positionId),callback:function ($$v) {_vm.positionId=$$v},expression:"positionId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('user.tel') + '*'}},[_c('validation-provider',{attrs:{"name":_vm.$t('user.tel'),"rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"plaintext":_vm.isPlainText},model:{value:(_vm.userTel),callback:function ($$v) {_vm.userTel=$$v},expression:"userTel"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),(false)?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('user.bloodType')}},[(_vm.isPlainText)?_c('b-form-input',{attrs:{"plaintext":""},model:{value:(_vm.showBloodTypeName),callback:function ($$v) {_vm.showBloodTypeName=$$v},expression:"showBloodTypeName"}}):_c('v-select',{attrs:{"label":"bloodName","options":_vm.optionBloodTypes,"reduce":function (option) { return option.bloodId; }},model:{value:(_vm.userBloodType),callback:function ($$v) {_vm.userBloodType=$$v},expression:"userBloodType"}})],1)],1):_vm._e(),(false)?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('user.congenitalDisease')}},[_c('b-form-input',{attrs:{"plaintext":_vm.isPlainText},model:{value:(_vm.userCongenitalDisease),callback:function ($$v) {_vm.userCongenitalDisease=$$v},expression:"userCongenitalDisease"}})],1)],1):_vm._e(),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('user.imageProfile') + _vm.showAsterisk}},[(_vm.userImagePath)?_c('thumbnail-file',{attrs:{"src":_vm.userImagePath}}):_vm._e(),_c('validation-provider',{attrs:{"name":_vm.$t('user.imageProfile'),"rules":_vm.id ? '' : 'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(!_vm.isPlainText)?_c('b-form-file',{attrs:{"accept":".jpg, .png","state":errors.length > 0 ? false : null},model:{value:(_vm.userImage),callback:function ($$v) {_vm.userImage=$$v},expression:"userImage"}}):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6","align-self":"end"}},[_c('b-form-group',{attrs:{"label":_vm.$t('user.email') + '*'}},[_c('validation-provider',{attrs:{"name":_vm.$t('user.email'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"plaintext":_vm.isPlainText},model:{value:(_vm.userEmail),callback:function ($$v) {_vm.userEmail=$$v},expression:"userEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),((_vm.id && (_vm.userData.userGroupId === 4 || _vm.userData.userGroupId === 5)) && !_vm.isPlainText)?_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('b-form-checkbox',{attrs:{"value":"Y"},model:{value:(_vm.userChangePassword),callback:function ($$v) {_vm.userChangePassword=$$v},expression:"userChangePassword"}},[_vm._v(" "+_vm._s(_vm.$t('user.changePassword'))+" ")])],1)],1):_vm._e(),(_vm.togglePasswordField)?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('user.password') + '*'}},[_c('validation-provider',{attrs:{"name":"password","rules":"required|min:8|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"password","state":errors.length > 0 ? false : null},model:{value:(_vm.userPassword),callback:function ($$v) {_vm.userPassword=$$v},expression:"userPassword"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1):_vm._e(),(_vm.togglePasswordField)?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('user.rePassword') + '*'}},[_c('validation-provider',{attrs:{"name":_vm.$t('user.rePassword'),"rules":"required|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"password","state":errors.length > 0 ? false : null},model:{value:(_vm.userRePassword),callback:function ($$v) {_vm.userRePassword=$$v},expression:"userRePassword"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1):_vm._e(),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('user.userGroup') + '*'}},[(_vm.isPlainText)?_c('b-form-input',{attrs:{"plaintext":""},model:{value:(_vm.showUserGroupName),callback:function ($$v) {_vm.showUserGroupName=$$v},expression:"showUserGroupName"}}):_c('validation-provider',{attrs:{"name":_vm.$t('user.userGroup'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:errors.length > 0 ? 'vue-select is-invalid' : null,attrs:{"label":"userGroupName","options":_vm.optionUserGroups,"reduce":function (option) { return option.userGroupId; }},model:{value:(_vm.userGroupId),callback:function ($$v) {_vm.userGroupId=$$v},expression:"userGroupId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),(_vm.userGroupId === 1)?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('user.leadSectionManager') + '*'}},[(_vm.isPlainText)?_c('b-form-input',{attrs:{"plaintext":""},model:{value:(_vm.showUserSectionManager),callback:function ($$v) {_vm.showUserSectionManager=$$v},expression:"showUserSectionManager"}}):_c('validation-provider',{attrs:{"name":_vm.$t('user.leadSectionManager'),"rules":_vm.requiredSectionManager},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:errors.length > 0 ? 'vue-select is-invalid' : null,attrs:{"label":"userFullName","options":_vm.optionSectionManagerLists,"reduce":function (option) { return option.userId; },"multiple":""},model:{value:(_vm.userSectionManager),callback:function ($$v) {_vm.userSectionManager=$$v},expression:"userSectionManager"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1):_vm._e()],1)],1),_c('b-card',{attrs:{"title":_vm.$t('user.emergencyContact')}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('user.emergencyName') + '*'}},[_c('validation-provider',{attrs:{"name":_vm.$t('user.emergencyName'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"plaintext":_vm.isPlainText},model:{value:(_vm.userEmergencyName),callback:function ($$v) {_vm.userEmergencyName=$$v},expression:"userEmergencyName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('user.emergencyTel') + '*'}},[_c('validation-provider',{attrs:{"name":_vm.$t('user.emergencyTel'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"plaintext":_vm.isPlainText},model:{value:(_vm.userEmergencyTel),callback:function ($$v) {_vm.userEmergencyTel=$$v},expression:"userEmergencyTel"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)],1),_c('b-card',{attrs:{"title":_vm.$t('user.attachments')}},[(!_vm.isPlainText)?_c('b-col',{staticClass:"text-right",attrs:{"cols":"12"}},[_c('b-button',{staticClass:"mb-2",attrs:{"variant":"primary"},on:{"click":_vm.addRow}},[_vm._v(_vm._s(_vm.$t('user.btnAddAttachment')))])],1):_vm._e(),_vm._l((_vm.userAttachmentLists),function(row,key){return _c('b-row',{key:key,staticClass:"attachment-row",attrs:{"align-v":"center"}},[_c('b-col',{attrs:{"md":"6","order":"2","order-md":"1"}},[_c('b-form-group',{attrs:{"label":_vm.$t('user.attachmentType') + '*',"vid":'attachmentType' + key}},[(_vm.isPlainText)?_c('div',[_vm._v(_vm._s(_vm.showAttachmentName(row.attachmentTypeId)))]):_c('validation-provider',{attrs:{"name":_vm.$t('user.attachmentType'),"rules":"required","vid":'attachmentType' + key},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:errors.length > 0 ? 'vue-select is-invalid' : null,attrs:{"label":"attachmentTypeName","options":_vm.optionAttachmentTypes,"reduce":function (option) { return option.attachmentTypeId; }},model:{value:(row.attachmentTypeId),callback:function ($$v) {_vm.$set(row, "attachmentTypeId", $$v)},expression:"row.attachmentTypeId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"text-right",attrs:{"md":"6","order":"1","order-md":"2"}},[(!_vm.isPlainText)?_c('b-button',{attrs:{"variant":"danger","size":"sm"},on:{"click":function($event){return _vm.deleteRow(key)}}},[_vm._v(" "+_vm._s(_vm.$t('general.btnDelete'))+" ")]):_vm._e()],1),_c('b-col',{attrs:{"md":"6","order":"3"}},[_c('b-form-group',{attrs:{"label":row.attachmentFilePath ? _vm.$t('user.attachmentFile') : _vm.$t('user.attachmentFile') + '*'}},[(row.attachmentFilePath)?_c('thumbnail-file',{attrs:{"src":row.attachmentFilePath}}):_vm._e(),_c('validation-provider',{attrs:{"name":_vm.$t('user.attachmentFile'),"rules":row.attachmentFilePath ? '' : 'required',"vid":'attachmentFile' + key},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(!_vm.isPlainText)?_c('b-form-file',{attrs:{"accept":".jpg, .gif, .pdf, .png","state":errors.length > 0 ? false : null},model:{value:(row.attachmentFile),callback:function ($$v) {_vm.$set(row, "attachmentFile", $$v)},expression:"row.attachmentFile"}}):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6","order":"4","align-self":"end"}},[_c('b-form-group',{attrs:{"label":_vm.$t('user.trainingExpireDate') + '*'}},[(_vm.isPlainText)?_c('div',[_vm._v(_vm._s(row.attachmentExpiredDate))]):_c('validation-provider',{attrs:{"name":_vm.$t('user.trainingExpireDate'),"rules":"required","vid":'attachmentExpiredDate' + key},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"config":_vm.$store.state.main.datePickerConfig},model:{value:(row.attachmentExpiredDate),callback:function ($$v) {_vm.$set(row, "attachmentExpiredDate", $$v)},expression:"row.attachmentExpiredDate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12","order":"4","align-self":"end"}},[_c('b-form-group',{attrs:{"label":_vm.$t('user.attachmentDesc')}},[_c('b-form-input',{attrs:{"plaintext":_vm.isPlainText},model:{value:(row.attachmentDescription),callback:function ($$v) {_vm.$set(row, "attachmentDescription", $$v)},expression:"row.attachmentDescription"}})],1)],1)],1)})],2),(!_vm.id)?_c('b-card',{attrs:{"title":_vm.$t('user.termAndCondition')}},[_c('b-form-checkbox',{model:{value:(_vm.userTermSelectAll),callback:function ($$v) {_vm.userTermSelectAll=$$v},expression:"userTermSelectAll"}},[_vm._v(" "+_vm._s(_vm.$t('general.selectAll'))+" ")]),_c('validation-provider',{attrs:{"name":_vm.$t('user.termAndCondition'),"rules":("required|exact_length:" + _vm.totalCompliance + "," + (_vm.$t('user.validateTermAndCondition')))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_vm._l((_vm.showOptionCompliance),function(row,key){return _c('b-form-group',{key:("com" + key),attrs:{"label":_vm.switchLanguage(row.complianceTypeNameTh, row.complianceTypeNameEn)}},[_c('b-form-checkbox-group',{attrs:{"options":row.complianceLists,"text-field":"complianceName","value-field":"complianceId","stacked":""},model:{value:(_vm.userTermAndCondition),callback:function ($$v) {_vm.userTermAndCondition=$$v},expression:"userTermAndCondition"}})],1)}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(_vm.id && _vm.userData.userGroupId === 4)?_c('b-card',{attrs:{"title":_vm.$t('user.blacklist')}},[(_vm.id && _vm.userData.userGroupId === 4)?_c('b-row',{attrs:{"align-v":"center"}},[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('b-form-checkbox',{staticClass:"custom-control-primary",attrs:{"value":"Y"},model:{value:(_vm.userBlacklist),callback:function ($$v) {_vm.userBlacklist=$$v},expression:"userBlacklist"}},[_vm._v(" "+_vm._s(_vm.$t('user.blacklist'))+" ")])],1)],1),(_vm.userBlacklist === 'Y')?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('user.attachmentDesc')}},[_c('b-form-input',{attrs:{"plaintext":_vm.isPlainText},model:{value:(_vm.userBlacklistDesc),callback:function ($$v) {_vm.userBlacklistDesc=$$v},expression:"userBlacklistDesc"}})],1)],1):_vm._e()],1):_vm._e()],1):_vm._e(),(!_vm.isPlainText)?_c('b-row',[_c('b-col',{staticClass:"text-center"},[_c('b-button',{staticClass:"my-1",attrs:{"type":"submit","variant":"success","disabled":_vm.isBtnDisabled}},[(_vm.isBtnDisabled)?_c('b-spinner',{attrs:{"small":""}}):_c('feather-icon',{attrs:{"icon":"SaveIcon"}}),_vm._v(" "+_vm._s(_vm.$t('general.btnSubmit'))+" ")],1)],1)],1):_vm._e()],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }