<template>
  <div>
    <b-overlay :show="isShowLoading">
      <validation-observer ref="validateForm" #default="{invalid}">
        <b-form @submit.prevent="saveData">

          <b-card :title="$t('user.personalInformation')">
            <b-row>
              <b-col md="6" v-if="userData.userCategory === 1">
                <b-form-group :label="$t('master.company.name') + '*'">
                  <b-form-input v-model="showCompanyName" plaintext v-if="id"/>

                  <validation-provider #default="{ errors }" :name="$t('master.company.name')" rules="required"  v-else>
                    <v-select v-model="companyId" label="companyName"
                              :options="optionCompanies" :reduce="option => option.companyId"
                              :class="errors.length > 0 ? 'vue-select is-invalid' : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6" v-if="userData.userCategory === 1">
                <b-form-group :label="$t('user.staffId') + '*'" v-if="isInternalCompany">
                  <validation-provider #default="{ errors }" :name="$t('user.staffId')" rules="required">
                    <b-form-input v-model="userStaffId" :state="errors.length > 0 ? false : null" :plaintext="isPlainText"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group :label="$t('user.idCard') + '*'">
                  <validation-provider #default="{ errors }" :name="$t('user.idCard')" rules="required">
                    <b-form-input v-model="userIdCard" :state="errors.length > 0 ? false : null" :plaintext="isPlainText"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group :label="$t('user.prefix') + '*'">
                  <b-form-input v-model="showPrefixName" plaintext v-if="isPlainText"/>

                  <validation-provider #default="{ errors }" :name="$t('user.prefix')" rules="required" v-else>
                    <v-select v-model="prefixId" label="prefixName"
                              :options="optionPrefixes" :reduce="option => option.prefixId"
                              :class="errors.length > 0 ? 'vue-select is-invalid' : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group :label="$t('user.firstName') + '*'">
                  <validation-provider #default="{ errors }" :name="$t('user.firstName')" rules="required">
                    <b-form-input v-model="userFirstName" :state="errors.length > 0 ? false : null" :plaintext="isPlainText"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group :label="$t('user.lastName') + '*'">
                  <validation-provider #default="{ errors }" :name="$t('user.lastName')" rules="required">
                    <b-form-input v-model="userLastName" :state="errors.length > 0 ? false : null" :plaintext="isPlainText"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group :label="$t('user.position') + '*'">
                  <b-form-input v-model="showPositionName" plaintext v-if="isPlainText"/>

                  <validation-provider #default="{ errors }" :name="$t('user.position')" rules="required" v-else>
                    <v-select v-model="positionId" label="positionName"
                              :options="optionPositions" :reduce="option => option.positionId"
                              :class="errors.length > 0 ? 'vue-select is-invalid' : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group :label="$t('user.tel') + '*'">
                  <validation-provider #default="{ errors }" :name="$t('user.tel')" rules="required|integer">
                    <b-form-input v-model="userTel" :state="errors.length > 0 ? false : null" :plaintext="isPlainText"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6" v-if="false">
                <b-form-group :label="$t('user.bloodType')">
                  <b-form-input v-model="showBloodTypeName" plaintext v-if="isPlainText"/>

                  <v-select v-model="userBloodType" label="bloodName"
                            :options="optionBloodTypes" :reduce="option => option.bloodId"
                            v-else
                  />
                </b-form-group>
              </b-col>

              <b-col md="6" v-if="false">
                <b-form-group :label="$t('user.congenitalDisease')">
                  <b-form-input v-model="userCongenitalDisease" :plaintext="isPlainText"/>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group :label="$t('user.imageProfile') + showAsterisk">
                  <thumbnail-file :src="userImagePath" v-if="userImagePath"/>
                  <validation-provider #default="{ errors }" :name="$t('user.imageProfile')" :rules="id ? '' : 'required'">
                    <b-form-file v-model="userImage" accept=".jpg, .png" :state="errors.length > 0 ? false : null" v-if="!isPlainText"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6" align-self="end">
                <b-form-group :label="$t('user.email') + '*'">
                  <validation-provider #default="{ errors }" :name="$t('user.email')" rules="required">
                    <b-form-input v-model="userEmail" :state="errors.length > 0 ? false : null" :plaintext="isPlainText"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="12" v-if="(id && (userData.userGroupId === 4 || userData.userGroupId === 5)) && !isPlainText">
                <b-form-group>
                  <b-form-checkbox v-model="userChangePassword" value="Y">
                    {{ $t('user.changePassword') }}
                  </b-form-checkbox>
                </b-form-group>
              </b-col>

              <b-col md="6" v-if="togglePasswordField">
                <b-form-group :label="$t('user.password') + '*'">
                  <validation-provider #default="{ errors }" name="password" rules="required|min:8|integer">
                    <b-form-input type="password" v-model="userPassword" :state="errors.length > 0 ? false : null"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6" v-if="togglePasswordField">
                <b-form-group :label="$t('user.rePassword') + '*'">
                  <validation-provider #default="{ errors }" :name="$t('user.rePassword')" rules="required|confirmed:password">
                    <b-form-input type="password" v-model="userRePassword" :state="errors.length > 0 ? false : null"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group :label="$t('user.userGroup') + '*'">
                  <b-form-input v-model="showUserGroupName" plaintext v-if="isPlainText"/>

                  <validation-provider #default="{ errors }" :name="$t('user.userGroup')" rules="required" v-else>
                    <v-select v-model="userGroupId" label="userGroupName"
                              :options="optionUserGroups" :reduce="option => option.userGroupId"
                              :class="errors.length > 0 ? 'vue-select is-invalid' : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6" v-if="userGroupId === 1">
                <b-form-group :label="$t('user.leadSectionManager') + '*'">
                  <b-form-input v-model="showUserSectionManager" plaintext v-if="isPlainText"/>

                  <validation-provider #default="{ errors }" :name="$t('user.leadSectionManager')" :rules="requiredSectionManager" v-else>
                    <v-select v-model="userSectionManager" label="userFullName"
                              :options="optionSectionManagerLists" :reduce="option => option.userId"
                              :class="errors.length > 0 ? 'vue-select is-invalid' : null"
                              multiple=""
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>


          </b-card>

          <b-card :title="$t('user.emergencyContact')">
            <b-row>
              <b-col md="6">
                <b-form-group :label="$t('user.emergencyName') + '*'">
                  <validation-provider #default="{ errors }" :name="$t('user.emergencyName')" rules="required">
                    <b-form-input v-model="userEmergencyName" :state="errors.length > 0 ? false : null" :plaintext="isPlainText"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group :label="$t('user.emergencyTel') + '*'">
                  <validation-provider #default="{ errors }" :name="$t('user.emergencyTel')" rules="required">
                    <b-form-input v-model="userEmergencyTel" :state="errors.length > 0 ? false : null" :plaintext="isPlainText"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>

          <b-card :title="$t('user.attachments')">
            <b-col cols="12" class="text-right" v-if="!isPlainText">
              <b-button variant="primary" @click="addRow" class="mb-2">{{ $t('user.btnAddAttachment') }}</b-button>
            </b-col>

            <b-row v-for="(row, key) in userAttachmentLists" :key="key" align-v="center" class="attachment-row">
              <b-col md="6" order="2" order-md="1">
                <b-form-group :label="$t('user.attachmentType') + '*'" :vid="'attachmentType' + key">
                  <div v-if="isPlainText">{{ showAttachmentName(row.attachmentTypeId) }}</div>

                  <validation-provider #default="{ errors }" :name="$t('user.attachmentType')" rules="required" :vid="'attachmentType' + key" v-else>
                    <v-select v-model="row.attachmentTypeId" label="attachmentTypeName"
                              :options="optionAttachmentTypes" :reduce="option => option.attachmentTypeId"
                              :class="errors.length > 0 ? 'vue-select is-invalid' : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6" order="1" order-md="2" class="text-right">
                <b-button variant="danger" size="sm" @click="deleteRow(key)" v-if="!isPlainText">
                  {{ $t('general.btnDelete') }}
                </b-button>
              </b-col>

              <b-col md="6" order="3">
                <b-form-group :label="row.attachmentFilePath ? $t('user.attachmentFile') : $t('user.attachmentFile') + '*'">
                  <thumbnail-file :src="row.attachmentFilePath" v-if="row.attachmentFilePath"/>
                  <validation-provider #default="{ errors }" :name="$t('user.attachmentFile')" :rules="row.attachmentFilePath ? '' : 'required'" :vid="'attachmentFile' + key">
                    <b-form-file v-model="row.attachmentFile" accept=".jpg, .gif, .pdf, .png" :state="errors.length > 0 ? false : null" v-if="!isPlainText"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6" order="4" align-self="end">
                <b-form-group :label="$t('user.trainingExpireDate') + '*'">
                  <div v-if="isPlainText">{{ row.attachmentExpiredDate }}</div>

                  <validation-provider #default="{ errors }" :name="$t('user.trainingExpireDate')" rules="required" :vid="'attachmentExpiredDate' + key" v-else>
                    <flat-pickr v-model="row.attachmentExpiredDate" class="form-control" :config="$store.state.main.datePickerConfig"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="12" order="4" align-self="end">
                <b-form-group :label="$t('user.attachmentDesc')">
                  <b-form-input v-model="row.attachmentDescription" :plaintext="isPlainText"/>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>

          <b-card :title="$t('user.termAndCondition')" v-if="!id">
            <b-form-checkbox v-model="userTermSelectAll">
              {{ $t('general.selectAll') }}
            </b-form-checkbox>

            <validation-provider #default="{ errors }" :name="$t('user.termAndCondition')"
                                 :rules="`required|exact_length:${totalCompliance},${$t('user.validateTermAndCondition')}`">
              <b-form-group
                  v-for="(row, key) in showOptionCompliance" :key="`com${key}`"
                  :label="switchLanguage(row.complianceTypeNameTh, row.complianceTypeNameEn)"
              >
<!--                <div v-for="option in row.complianceLists" :key="option.complianceId">-->

                  <b-form-checkbox-group
                      v-model="userTermAndCondition"
                      :options="row.complianceLists"
                      text-field="complianceName"
                      value-field="complianceId"
                      stacked
                  >
                  </b-form-checkbox-group>
<!--                </div>-->
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>

            </validation-provider>
          </b-card>

          <b-card :title="$t('user.blacklist')" v-if="id && userData.userGroupId === 4">
            <b-row align-v="center" v-if="id && userData.userGroupId === 4">
              <b-col md="6">
                <b-form-group>
                  <b-form-checkbox v-model="userBlacklist" value="Y" class="custom-control-primary">
                    {{ $t('user.blacklist') }}
                  </b-form-checkbox>
                </b-form-group>
              </b-col>

              <b-col md="6" v-if="userBlacklist === 'Y'">
                <b-form-group :label="$t('user.attachmentDesc')">
                  <b-form-input v-model="userBlacklistDesc" :plaintext="isPlainText"/>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>



          <b-row v-if="!isPlainText">
            <b-col class="text-center">
              <b-button type="submit" variant="success" class="my-1" :disabled="isBtnDisabled">
                <b-spinner small v-if="isBtnDisabled"/>
                <feather-icon icon="SaveIcon" v-else/>
                {{ $t('general.btnSubmit') }}
              </b-button>
            </b-col>
          </b-row>

        </b-form>
      </validation-observer>

    </b-overlay>
  </div>

</template>

<script>
import {BCard, BCardText, BRow, BCol, BFormGroup, BFormInput, BSpinner, BForm, BFormCheckbox, BFormCheckboxGroup, BFormFile, BButton, BOverlay} from 'bootstrap-vue'
import {required, min, confirmed, integer, exact_length} from '@validations'
import tableMixins from "@/mixins/tableMixins"
import masterMixins from "@/mixins/masterMixins"
import formMixins from "@/mixins/formMixins"
import {UserService, UserAttachmentService, FileStorageService} from "@/services"

const userService = new UserService()
const userAttachmentService = new UserAttachmentService()
const fileStorageService = new FileStorageService()

export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormFile,
    BButton,
    BSpinner,
    BOverlay,
  },
  mixins: [tableMixins, masterMixins, formMixins],
  async created() {
    this.id = this.$route.params.id

    if (this.id) {
      await this.getData()
      await this.getAttachment()
    }

    let [listCompany, listPrefixes, listUserGroup, listPositions, listAttachmentType, listCompliance] = await Promise.all([
      this.listCompany(),
      this.listPrefix(),
      this.listUserGroup(),
      this.listPosition(),
      this.listAttachmentType(),
      this.listCompliance(),
      this.listSectionManager(),
    ])

    this.optionCompanies = listCompany
    this.optionPrefixes = listPrefixes
    this.optionPositions = listPositions
    this.optionAttachmentTypes = listAttachmentType
    this.optionCompliances = listCompliance

    if (this.userData.userCategory === 1) {
      this.optionUserGroups = listUserGroup
    } else {
      this.companyId = this.userData.companyId
      this.optionUserGroups = listUserGroup.filter(option => [5, 6].includes(option.userGroupId))
    }

    this.isShowLoading = false
    this.enabledCheckBackButton()
  },
  data: () => ({
    isShowLoading: true,
    isBtnDisabled: false,
    id: '',
    userData: JSON.parse(localStorage.getItem('userData')),
    departmentName: '',

    // companyId: null,
    /*companyId: 2,
    prefixId: 3,
    userFirstName: 'สมศรี',
    userLastName: 'มีชัย',
    userIdCard: '11037005700832',
    // positionId: null,
    positionId: 1,
    // userBloodType: null,
    userBloodType: 3,
    userCongenitalDisease: '-',
    userTel: '0818188216',
    userImage: [],
    userEmail: 'test01@gmail.com',
    userPassword: '!QAZ2wsx3edc',
    userRePassword: '!QAZ2wsx3edc',
    // userGroupId: null,
    userGroupId: 5,
    userCategory: 2,
    userEmergencyName: 'aa',
    userEmergencyTel: 'bb',*/
    userTermAndCondition: [],
    userImagePath: '',

    companyId: '',
    prefixId: '',
    userFirstName: '',
    userLastName: '',
    userIdCard: '',
    positionId: '',
    // userBloodType: '',
    userCongenitalDisease: '',
    userTel: '',
    userEmail: '',
    userPassword: '',
    userRePassword: '',
    userGroupId: '',
    userCategory: '',
    userEmergencyName: '',
    userEmergencyTel: '',
    userImage: [],
    userBlacklist: 'N',
    userChangePassword: 'N',
    userStaffId: '',
    userBlacklistDesc: '',
    userTermSelectAll: false,
    userSectionManager: [],

    userAttachmentLists: [],
    optionCompanies: [],
    optionPrefixes: [],
    optionUserGroups: [],
    optionPositions: [],
    // optionBloodTypes: [],
    optionAttachmentTypes: [],
    optionCompliances: [],
    optionSectionManagerLists: [],
    option: [
      {
        prefixId: 1,
        prefixName: 'Mr.'
      }, {
        prefixId: 2,
        prefixName: 'Ms.'
      }, {
        prefixId: 3,
        prefixName: 'Miss.'
      },
    ],
  }),
  watch: {
    userTermSelectAll() {
      this.checkAll()
    }
  },
  computed: {
    showAsterisk() {
      return this.id ? '' : '*'
    },
    requiredSectionManager() {
      return this.userGroupId === 1 ? 'required' : ''
    },
    totalCompliance() {
      let total = 0
      this.optionCompliances.map(comType => {
        total += comType.complianceLists.length
      })

      return total
    },
    togglePasswordField() {
      if ((this.id && this.userChangePassword === 'Y') || !this.id) {
        return true
      } else {
        return false
      }
    },
    isInternalCompany() {
      let findCompany = this.optionCompanies.find(data => data.companyId === this.companyId)
      return !!(findCompany && findCompany.companyInternal === 'Y')
    },
    isPlainText() {
      return this.$route.name === 'pageUserView'
    },
    showCompanyName() {
      if(this.id) {
        let result = this.optionCompanies.find(data => data.companyId === this.companyId)
        return result ? result.companyName : ''
      } else {
        return ''
      }
    },
    showPrefixName() {
      if(this.id) {
        let result = this.optionPrefixes.find(data => data.prefixId === this.prefixId)
        return result ? result.prefixName : ''
      } else {
        return ''
      }
    },
    showPositionName() {
      if(this.id) {
        let result = this.optionPositions.find(data => data.positionId === this.positionId)
        return result ? result.positionName : ''
      } else {
        return ''
      }
    },
    showBloodTypeName() {
      if(this.id) {
        let result = this.optionBloodTypes.find(data => data.bloodId === this.bloodId)
        return result ? result.bloodName : ''
      } else {
        return ''
      }
    },
    showUserGroupName() {
      if(this.id) {
        let result = this.optionUserGroups.find(data => data.userGroupId === this.userGroupId)
        return result ? result.userGroupName : ''
      } else {
        return ''
      }
    },
    showUserSectionManager() {
      if(this.userSectionManager.length > 0) {
        let userSectionManager = []
        return this.optionSectionManagerLists
            .filter(user => this.userSectionManager.includes(user.userId))
            .map(user => user.userFullName)
            .join(', ')
      } else {
        return ''
      }
    },
    showOptionCompliance() {
      return this.optionCompliances.map(row => {
        let complianceLists = row.complianceLists.map(option => {
          return {
            ...option,
            complianceName: this.switchLanguage(option.complianceNameTh, option.complianceNameEn)
          }
        })

        return {
          ...row,
          complianceLists: complianceLists
        }
      })
    }
  },
  methods: {
    async getData() {
      let queryResult = await userService.getData(this.id)
      if (queryResult.isSuccess) {
        let {data} = queryResult
        let {result} = data
        this.companyId = result.companyId
        this.prefixId = result.prefixId
        this.userFirstName = result.userFirstName
        this.userLastName = result.userLastName
        this.userIdCard = result.userIdCard
        this.positionId = result.positionId
        // this.userBloodType = result.userBloodType
        // this.userCongenitalDisease = result.userCongenitalDisease
        this.userTel = result.userTel
        this.userEmail = result.userEmail
        this.userGroupId = result.userGroupId
        this.userCategory = result.userCategory
        this.userEmergencyName = result.userEmergencyName
        this.userEmergencyTel = result.userEmergencyTel
        this.userImagePath = result.userImage
        this.userStaffId = result.userStaffId
        this.userBlacklist = result.userBlacklist
        this.userBlacklistDesc = result.userBlacklistDesc
        if(result.userSectionManager) {
          let userSectionManagers = result.userSectionManager.split(',')
          this.userSectionManager = userSectionManagers.map(manager => parseInt(manager))
        } else {
          this.userSectionManager = []
        }
      } else {
        this.$store.commit('main/setToastError', queryResult.data)
        await this.$router.push({name: 'pageUser'})
      }
    },
    async saveData() {
      this.$refs.validateForm.validate()

      this.$refs.validateForm.validate().then(async success => {
        if (success) {
          this.isBtnDisabled = true
          let uploadResult = {fileId: null}
          if (Object.entries(this.userImage).length > 0) {
            uploadResult = await fileStorageService.upload(this.userImage)
            // {isSuccess: true, message: 'Success', fileId: 8}
            if (!uploadResult.isSuccess) {
              this.$store.commit('main/setToastError', {message: uploadResult.message})
              this.isBtnDisabled = false
              return false
            }
          }

          let actionData = {
            fileId: uploadResult.fileId,
            companyId: this.companyId,
            prefixId: this.prefixId,
            userFirstName: this.userFirstName,
            userLastName: this.userLastName,
            userIdCard: this.userIdCard,
            positionId: this.positionId,
            // userBloodType: this.userBloodType,
            // userCongenitalDisease: this.userCongenitalDisease,
            userTel: this.userTel,
            userEmail: this.userEmail,
            userGroupId: this.userGroupId,
            userEmergencyName: this.userEmergencyName,
            userEmergencyTel: this.userEmergencyTel,
            userChangePassword: this.userChangePassword,
            userPassword: this.userPassword,
            userRePassword: this.userRePassword,
            userStaffId: this.userStaffId,
            userSectionManager: this.userSectionManager.join(','),
          }

          let saveResult
          if (this.id) {
            actionData.userBlacklist = this.userBlacklist
            actionData.userBlacklistDesc = this.userBlacklistDesc

            saveResult = await userService.updateData(this.id, actionData)
            await this.saveAttachment(this.id)
          } else {
            saveResult = await userService.createData(actionData)

            if (saveResult.isSuccess) {
              await this.saveAttachment(saveResult.data.result.userId)
            }
          }

          this.isBtnDisabled = false

          if (saveResult.isSuccess) {
            this.$store.commit('main/setToastSuccess', {title: 'Save success', text: ''})
            await this.$router.push({name: 'pageUser'})
          } else {
            this.$store.commit('main/setToastError', saveResult.data)
          }
        }
      })
    },
    addRow() {
      this.userAttachmentLists.push({
        attachmentId: '',
        attachmentDescription: '',
        attachmentExpiredDate: '',
        attachmentTypeId: null,
        attachmentFile: [],
        attachmentFilePath: '',
        attachmentFileRealName: '',
        attachmentNum: this.userAttachmentLists.length + 1,
      })
    },
    deleteRow(row) {
      this.userAttachmentLists.splice(row, 1)
      this.userAttachmentLists = this.userAttachmentLists.map((data, key) => ({...data, attachmentNum: key + 1}))
    },
    async getAttachment() {
      let results = []
      let queryResult = await userAttachmentService.listData({userId: this.id})
      if (queryResult.isSuccess) {
        queryResult.data.results.map((result, key) => {
          results.push({
            attachmentId: result.userAttachmentId,
            attachmentDescription: result.userAttachmentDescription,
            attachmentExpiredDate: result.userAttachmentExpiredDate,
            attachmentTypeId: result.userAttachmentTypeId,
            attachmentFile: [],
            attachmentFilePath: result.userAttachmentImage,
            attachmentFileRealName: result.userAttachmentImageRealName,
            attachmentNum: key + 1,
          })
        })

        this.userAttachmentLists = results
      }
    },
    async saveAttachment(userId) {
      let attachmentLists = []
      await Promise.all(
          this.userAttachmentLists.map(async data => {
            let row = {
              attachmentId: data.attachmentId,
              attachmentDescription: data.attachmentDescription,
              attachmentExpiredDate: data.attachmentExpiredDate,
              attachmentTypeId: data.attachmentTypeId,
              attachmentNum: data.attachmentNum,
            }
            if (Object.entries(data.attachmentFile).length > 0) {
              let uploadResult = await fileStorageService.upload(data.attachmentFile)
              row.attachmentFileId = uploadResult.fileId
            }
            attachmentLists.push(row)
          })
      )

      let actionData = {
        userAttachmentLists: attachmentLists,
        userId: userId
      }

      await userAttachmentService.saveData(actionData)

    },
    showAttachmentName(id) {
      if(this.id) {
        let result = this.optionAttachmentTypes.find(data => data.attachmentTypeId === id)
        return result ? result.attachmentTypeName : ''
      } else {
        return ''
      }
    },
    checkAll() {
      if(this.userTermSelectAll) {
        this.optionCompliances.map(row => {
          row.complianceLists.map(option => {
            this.userTermAndCondition.push(option.complianceId)
          })
        })
      } else {
        this.userTermAndCondition = []
      }
    },
    async listSectionManager() {
      let queryResult = await userService.listSectionManagerData()
      this.optionSectionManagerLists = queryResult.isSuccess ? queryResult.data.results : []
    }
  }
}
</script>

<style lang="scss">
.attachment-row {
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 25px;
  padding-bottom: 10px;
}
</style>
